<template>
  <div class="layer-seminar-guide KMAView-type">
    <div class="seminar-guide-wrap">
      <button
        class="btn-close"
        @click="closeGuide"
      />
      <p class="guide-img">
        <img src="@/assets/images/contents/KMA-guide.png">
      </p>
      <div class="txt-area">
        <p class="title">
          닥터인포에서 대한의사협회 연수교육 일정을 한 번에 확인하세요!
        </p>
        <div class="round-box">
          <p class="title">KMA 연수 교육
            이렇게 이용하실 수 있어요!</p>
          <ul>
            <li>대한의사협회에서 제공하는 연수 교육 일정을 한 눈에 확인하세요.</li>
            <li>교육 리스트를 클릭하면 KMA 연수 교육 사이트에서 제공하는 상세 정보 페이지로 이동합니다. 선택한 연수 교육을 신청하고 싶은 경우 해당 사이트에서 안내에 따라 진행해 주세요.
              ※ KMA 연수 교육은 자동 로그인 및 교육 신청이 불가합니다.</li>
            <li>KMA 사이트에서 신청한 교육이나 관심 있는 교육은 MY 세미나에 등록해 보세요. 해당 일정은 MY 세미나 캘린더에서 확인할 수 있습니다.</li>
          </ul>
        </div>
      </div>

      <div class="checkbox-white-wrap">
        <input
          id="againView"
          v-model="again"
          type="checkbox"
        >
        <label for="againView">다시보지않기</label>
      </div>
    </div>
  </div>
</template>

<script>
import { setPopupOption } from '@/common/popup/service'

export default {
  data() {
    return {
      again: false
    }
  },
  methods: {
    closeGuide() {
      if (this.again) {
        const popup = JSON.parse(localStorage.getItem('popup'))

        popup.kmaGuide = true

        setPopupOption(popup)
      }

      this.$parent.guidePopup = false
    }
  }
}
</script>
