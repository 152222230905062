var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layer-seminar-guide KMAView-type" }, [
    _c("div", { staticClass: "seminar-guide-wrap" }, [
      _c("button", { staticClass: "btn-close", on: { click: _vm.closeGuide } }),
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "checkbox-white-wrap" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.again,
              expression: "again",
            },
          ],
          attrs: { id: "againView", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.again)
              ? _vm._i(_vm.again, null) > -1
              : _vm.again,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.again,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.again = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.again = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.again = $$c
              }
            },
          },
        }),
        _c("label", { attrs: { for: "againView" } }, [_vm._v("다시보지않기")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "guide-img" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/contents/KMA-guide.png") },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "txt-area" }, [
      _c("p", { staticClass: "title" }, [
        _vm._v(
          " 닥터인포에서 대한의사협회 연수교육 일정을 한 번에 확인하세요! "
        ),
      ]),
      _c("div", { staticClass: "round-box" }, [
        _c("p", { staticClass: "title" }, [
          _vm._v("KMA 연수 교육 이렇게 이용하실 수 있어요!"),
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "대한의사협회에서 제공하는 연수 교육 일정을 한 눈에 확인하세요."
            ),
          ]),
          _c("li", [
            _vm._v(
              "교육 리스트를 클릭하면 KMA 연수 교육 사이트에서 제공하는 상세 정보 페이지로 이동합니다. 선택한 연수 교육을 신청하고 싶은 경우 해당 사이트에서 안내에 따라 진행해 주세요. ※ KMA 연수 교육은 자동 로그인 및 교육 신청이 불가합니다."
            ),
          ]),
          _c("li", [
            _vm._v(
              "KMA 사이트에서 신청한 교육이나 관심 있는 교육은 MY 세미나에 등록해 보세요. 해당 일정은 MY 세미나 캘린더에서 확인할 수 있습니다."
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }