import store from '@/store'
import axios from '@axios'

export const getPopupOption = async () => {
  const loginId = store.getters['infoData/getShaEnLicenseNum']

  axios.post('/fu/popup', {
    userLoginId: loginId
  })
    .then(rs => {
      localStorage.setItem('popup', JSON.stringify(rs.data))

      store.commit('util/setPopupSetting', true)
    })
    .catch(() => {
    })
}

export const setPopupOption = async (request) => {
  localStorage.setItem('popup', JSON.stringify(request))

  // eslint-disable-next-line no-param-reassign
  request.userLoginId = store.getters['infoData/getShaEnLicenseNum']

  axios.put('/fu/popup',
    request)
    .then(() => {
    })
    .catch(() => {
    })
}
